<template>
    <div class="app-container">
        <el-form :size="$store.getters.size" :inline="true" :model="queryParams" ref="queryParams">
            <el-form-item>
                <el-select filterable placeholder="选择仓库" v-model="queryParams.warId" clearable :disabled="disabled" >
                    <el-option v-for="(item, index) in warehouseOption" :label="item.label" :key="index" :value="item.value" />
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="querySearch" :disabled="disabled">查询</el-button>
<!--                <el-button type="primary" plain v-if="realTime" @click="closeRealTime">关闭实时数据</el-button>-->
<!--                <el-button type="warning" plain v-else @click="openRealTime">启动实时数据</el-button>-->
            </el-form-item>
        </el-form>
        <div class="monitor-content">
            <div class="map">
                <el-amap vid="GaodeMap" :center="center" class="bm-view" :zoom="zoom" ref="GaodeMap">

                    <el-amap-marker :position="item.center" v-for="(item, index) in positions" :key="index" :extData="item"
                        :content="markerContent(item)" :offset="[-50,-58]" :events="markerEvents">
                    </el-amap-marker>

                    <el-amap-info-window
                        v-if="visible" :visible="visible" class="window-info" :position="driverItem.center" :events="windowEvents"
                        :size="[220,142]" :content="windowContent(driverItem)" :offset="[0, -30]">
                    </el-amap-info-window>
                </el-amap>
            </div>
        </div>
    </div>
</template>

<script>
    import {getConstantly} from '@/api/delivery/traffic_trail'

    export default {
        name: "Monitor",
        data(){
            return {
                iconAddress: ['http://cdn.boofoo.top/q_addr_1.png', 'http://cdn.boofoo.top/q_addr_4.png', 'http://cdn.boofoo.top/q_addr_3.png'],
                labelStyle_0: {color: '#FFF','border-color': 'green', fontSize : '16px','margin-top':'-60px', 'backgroundColor': 'green'},
                labelStyle_1: {color: '#FFF','border-color': '#999', fontSize : '16px','margin-top':'-60px', 'backgroundColor': '#999'},
                labelStyle_2: {color: '#FFF','border-color': 'red', fontSize : '16px','margin-top':'-60px', 'backgroundColor': 'red'},
                warehouseOption: this.$store.getters.warehouse,
                queryParams: {
                    warId: undefined,
                },
                positions: [],
                driverItem: {},
                visible: false,
                center: [114.1056235322873, 22.802298117671754],
                zoom: 14,
                realTime: undefined,
                disabled: false,
                markerEvents: {
                    click : (e) => {
                        const exData = e.target.De.extData;
                        this.driverItem = exData
                        this.visible = true
                    },
                },
                windowEvents : {
                    close: () => {
                        this.visible = false
                    }
                }
            }
        },
        methods: {
            /** 查询 */
            querySearch(){
                this.getData();
                if(this.queryParams.warId) {
                    this.warehouseOption.forEach(item => {
                        if (item.value === this.queryParams.warId) {
                            this.center = [Number(item.lng), Number(item.lat)];
                        }
                    })
                }
            },
            handler ({BMap, map}) {
            },
            /** 开启定时查询 */
            openRealTime(){
                if(!this.queryParams.warId){
                    this.$message.error("请选择仓库")
                    return false;
                }
                // 每5秒查询一次数据
                const _this = this;
                this.getData();
                this.disabled = true;
                this.realTime = window.setInterval(()=>{
                    _this.getData();
                }, 10000);
                this.$message.success("开启查看司机实时位置成功");
            },
            /** 关闭实时查询 */
            closeRealTime(){
                this.disabled = false
                if(this.realTime){
                    window.clearInterval(this.realTime);
                    this.realTime = undefined;
                    this.$message.success("关闭查看司机实时位置成功");
                }
            },
            getClickInfo (e) {
                // console.log(e.point.lng)
                // console.log(e.point.lat)
            },

            windowContent(item){
                let typeStr = item.type === 0 ? '在线' : item.type === 2 ? '长时间未移动' : '离线'
                return `<div style="margin-top: 10px"><p><label>司机姓名</label>：<span>${item.driverName}</span></p>`+
                        `<p><label>联系电话</label>：<span>${item.driverTel}</span></p>`+
                        `<p><label>车号</label>：<span>${item.plat}</span></p>`+
                        `<p><label>车牌号</label>：<span>${item.vehicle}</span></p></div>`+
                        `<p><label>状态</label>：<span>${typeStr}</span></p>`;
            },
            markerContent(item){
                const style = item.style;
                return  `<div style="width:100px;text-align:center">`+
                        // `<p style="font-weight:bold;margin-bottom: 3px;font-size:16px;background-color:${style.backgroundColor};color:${style.color};">${item.vehicle}</p>`+
                        `<p style="font-weight:bold;margin-bottom: 3px;font-size:16px;background-color:${style.backgroundColor};color:${style.color};">${item.plat}-${item.driverName}</p>`+
                        `<img style="width:30px" src="${item.icon}" /></div>`;
            },
            /** 发送请求 */
            getData(){
                if(!this.queryParams.warId){
                    if(!this.realTime){
                        window.clearInterval(this.realTime);
                        this.realTime = undefined
                    }
                    this.$message.error("请选择仓库");
                    return false;
                }
                this.disabled = true
                getConstantly(this.queryParams).then(({code, data}) => {
                    this.disabled = false
                    if(code === 0){
                        this.positions = data.filter(item => {
                            item.style = this.labelStyle_0;
                            item.icon = this.iconAddress[0];
                            item.type = 0

                            const createTime = new Date(item.lastTrailRout.createTime).getTime();
                            const nowTime = new Date().getTime();

                            if(item.beyond){  // 判断是否超过10分钟未动
                                item.style = this.labelStyle_2;
                                item.icon = this.iconAddress[2];
                                item.type = 2
                            }

                            // 超过5分钟没有上报数据 灰色显示
                            if(nowTime - createTime > (5 * 60 * 1000)){
                                item.style = this.labelStyle_1;
                                item.icon = this.iconAddress[1];
                                item.type = 1
                            }

                            item.center = [Number(item.lastTrailRout.lng), Number(item.lastTrailRout.lat)]
                            item.show = false
                            return item;
                        })
                        //this.center = this.positions[0].center
                    }
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .monitor-content{
        .map{
            padding: 10px;

            .bm-view{
                width: auto;
                height: 750px;

                .window-info{
                    p{
                        line-height: 1.5;
                    }
                    span {
                        color: rgb(144,147,153);
                        margin-left: 5px;
                    }
                    label{
                        text-align: justify;
                        text-align-last: justify;
                        display: inline-table;
                        color: rgb(81,90,110);
                        width: 80px;
                    }
                }

            }
        }
    }
</style>
